<template>
    <v-container>
        <orders-and-laboratories client-view/>
    </v-container>
</template>

<script>
import OrdersAndLaboratories from "@/components/client/OrdersAndLaboratories";
export default {
    name: "OrdersLaboratories",
    components: {OrdersAndLaboratories}
}
</script>

<style scoped>

</style>